import Button from "@/components/button";
import Categorized from "@/components/categorized";
import Dropdown from "@/components/dropdown";
import Input from "@/components/input";
import Tag from "@/components/tag";
import Badge from "@/components/badge";
import Tags from "@/components/tags";
import TextArea from "@/components/text-area";
import Upload from "@/components/upload";
import {get} from "lodash";
import {DateTime} from "luxon";
import {mapGetters} from "vuex";

import axios from "@/axios";

export default {
    name: "neo-social-post",
    components: {
        "neo-tag": Tag,
        "neo-button": Button,
        "neo-categorized": Categorized,
        "neo-tags": Tags,
        "neo-dropdown": Dropdown,
        "neo-upload": Upload,
        "neo-input": Input,
        "neo-text-area": TextArea,
        "neo-badge": Badge,
    },
    props: {
        type: String,
        content: Object,
        translate: {
            type: Boolean,
            default: false,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
        ragstatus: {
            type: String,
            default: "Red",
        },
    },
    data() {
        return {
            isEdit: false,
            get: get,
            controls: {
                date: "",
                content: "",
            },
            isPostTextEdit: false,
            postText: "",
            postTextHeight: "",
            showOriginalPost: false,
        };
    },
    computed: {
        ...mapGetters(["getCaseId", "getMatchingProfile", "getReadOnlyMode"]),
        getFormattedTags() {
            return this.content.categories.analyst.map((elem) => {
                if (typeof elem === "object" && elem !== null) return elem;
                return {tag: elem};
            });
        },
        checkEditable() {
            return this.content.content === this.content.language.text_translated;
        },
        translationExists() {
            return this.content.language.text_translated != this.content.content;
        },
        translationEdited() {
            return this.content.language.text_translated != this.content.language.text_translated_edited;
        },
    },
    mounted() {
        if (!("text_translated_edited" in this.content.language)) {
            this.$set(this.content.language, "text_translated_edited", this.content.language.text_translated);
        }
        this.postText = this.content.language.text_translated_edited;
        this.onCancel();
    },
    methods: {
        onOriginalTranslation() {},
        onPostTextEdit() {
            this.isPostTextEdit = true;
            if (this.isPostTextEdit) {
                let textHeight = this.$refs.posttext.getBoundingClientRect().height;
                this.postTextHeight = textHeight;
            }
        },
        onPostTextSave() {
            this.isPostTextEdit = false;
            this.content.language.text_translated_edited = this.postText;
            this.updateContent(null, this.postText);
            this.$store.dispatch("updatePost", {
                post: this.content,
                changes: this.content,
            });
        },
        onPostTextCancel() {
            this.postText = this.content.language.text_translated_edited;
            this.isPostTextEdit = false;
        },
        auto_grow() {
            this.$refs.posttextarea.style.height = "0px";
            this.$refs.posttextarea.style.height = this.$refs.posttextarea.scrollHeight + "px";
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromFormat(dt, "EEE MMM dd HH:mm:ss ZZZ yyyy");
            if (!parsed_dt.invalid) return parsed_dt.toFormat("EEE, MMMM dd yyyy, hh:mm:ss a");
            return dt;
        },
        updateContent(categories = null, translation) {
            axios
                .put(`/social-platform-data`, {
                    case_id: this.getCaseId,
                    platform: this.getMatchingProfile.platform,
                    username: this.getMatchingProfile.username,
                    content_id: this.content._id,
                    include_in_report: this.content.include_in_report,
                    analyst_added: this.content?.analyst_added ?? false,
                    flagged: this.content.flagged,
                    categories: categories == null ? this.content.categories.analyst : categories,
                    translation: translation ? translation : this.content.language.text_translated_edited,
                })
                .then((response) => {});
        },
        onTagChanges(tags) {
            const changes = {
                ...this.content,
                categories: {...this.content.categories, analyst: tags.map((e) => e.tag)},
            };
            if (this.content.include_in_report) {
                this.updateContent(tags.map((e) => e.tag));
            }
            changes.metadata.created_at = this.controls.date;
            changes.content = this.controls.content;
            this.$store.dispatch("updatePost", {
                post: this.content,
                changes,
            });
        },
        onContentChanges() {
            const changes = {
                ...this.content,
            };

            changes.metadata.created_at = this.controls.date;
            changes.content = this.controls.content;
            this.$store.dispatch("updatePost", {
                post: this.content,
                changes,
            });
            this.isEdit = false;
        },
        onDeletePost() {
            this.$store.dispatch("deletePost", this.content);
        },
        onCancel() {
            this.isEdit = false;
            this.controls.date = this.content.metadata.created_at;
            this.controls.content = get(this.content, this.translate ? "language.text_translated" : "content");
        },
        onInclude() {
            this.$store.dispatch("includeInReport", this.content);
            this.updateContent();
        },
        onRevert() {
            this.$store.dispatch("removeFromReport", this.content);
            this.updateContent(this.content.categories.system);
        },
    },
};
