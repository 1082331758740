export default {
    name: "neo-text-area",
    components: {},
    props: ["placeholder", "label", "value", "margin", "bg", "border", "borderColor"],
    data() {
        return {};
    },
    computed: {},
    created() {},
    methods: {},
};
