export default {
    name: "neo-upload",
    components: {},
    props: ["label", "margin", "borderColor"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {
        onChange(value) {
            this.$emit("change", value);
        },
    },
};
